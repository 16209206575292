<template>
  <div class="login">
    <div class="box">
      <img src="../assets/image/logo.png" alt="千年中康" />
      <div class="form">
        <el-input placeholder="请输入用户名" v-model="admin">
          <i slot="prefix" class="el-input__icon el-icon-user-solid"></i>
        </el-input>
        <el-input
          placeholder="请输入密码"
          type="password"
          v-model="password"
          @keyup.enter.native="YZM = true"
        >
          <!-- <el-input
          placeholder="请输入密码"
          type="password"
          v-model="password"
        > -->
          <i slot="prefix" class="el-input__icon el-icon-s-cooperation"></i>
        </el-input>
        <button class="denglu" @click="YZM = true" type="info">登录</button>
      </div>
    </div>

    <el-dialog :visible.sync="YZM" width="400px">
      <verify
        :codeLength="4"
        @keyup.enter.native="yzmsucces"
        @success="yzmsucces"
        @error="yzmerror"
      ></verify>
    </el-dialog>

    <vue-particles
      color="#dedede"
      :particleOpacity="0.7"
      :particlesNumber="80"
      shapeType="circle"
      :particleSize="4"
      linesColor="#dedede"
      :linesWidth="1"
      :lineLinked="true"
      :lineOpacity="0.4"
      :linesDistance="150"
      :moveSpeed="3"
      :hoverEffect="true"
      hoverMode="grab"
      :clickEffect="true"
      clickMode="push"
      class="lizi"
    ></vue-particles>
  </div>
</template>
<script>
import Verify from "vue2-verify";
import { adminlogin, getorderlist } from "@/api/api.js";
export default {
  components: {
    Verify,
  },
  data() {
    return {
      YZM: false,
      admin: "",
      password: "",
    };
  },
  methods: {
    async login() {
      if (this.admin.trim() == "" || this.password.trim() == "") {
        return this.$message.error("用户名或者密码不能为空");
      }
      const { data } = await adminlogin({
        user_name: this.admin.trim(),
        user_pwd: this.password.trim(),
      });
      console.log(this.admin.trim());
      if (data.code != 200)
        return this.$message.error("登录失败，账号或密码错误， 请稍后重试");

      window.localStorage.setItem("admin_token", data.data.admin_token);
      window.localStorage.setItem("admin_roleID", data.data.role_id);
      window.localStorage.setItem("admin_name", this.admin.trim());
      this.$message.success("登录成功!");
      this.$router.push("/");
    },
    yzmsucces(e) {
      this.YZM = false;
      this.login();
    },
    yzmerror(e) {
      this.YZM = false;
      this.$message.error("验证码错误, 请重试");
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped lang="less">
.login {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .box {
    width: 1000px;
    height: 400px;
    // background-color: white;
    border-radius: 5px;
    position: relative;
    display: flex;
    align-items: center;
    img {
      z-index: 4;
      margin: 60px 0 0 150px;
      height: 200px;
      padding: 40px;
      // background-image: linear-gradient(to right, #c91506 , #e35501);
      // background-color: #ffffff;
      border-radius: 10px;
      border: 1px solid #999;
    }
    .form {
      width: 403px;
      height: 182px;
      padding: 45px 0 0 45px;
      // border-top: 1px solid #eee;
      z-index: 2;
      position: absolute;
      right: 95px;
      bottom: 88px;
      .el-input {
        width: 300px;
        margin-bottom: 20px;
      }
      .denglu {
        margin-left: 50px;
        width: 200px;
        height: 30px;
        border: none;
        background-color: #1bbdff;
        border-radius: 5px;
        font-size: 14px;
        color: #ffffff;
        &:hover {
          background-color: #36d7b7;
        }
      }
    }
  }
}

/deep/.el-dialog__header {
  display: none;
}
/deep/.el-dialog__body {
  display: flex;
  height: 200px;
  justify-content: center;
  position: relative;
  div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /deep/.verify-code-area {
      position: relative;
      top: -15px;
    }
  }
}
/deep/.verify-btn {
  margin: 0;
  position: absolute;
  bottom: 20px;
  right: 25%;
}
.lizi {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  // z-index: -1;
  background-image: radial-gradient(#002953, #041222); //添加渐变背景颜色
}
</style>